import PropTypes from 'prop-types';
import { useState } from 'react';
import _ from 'lodash';
// @mui
import {
  Card,
  Typography,
  CardActions,
  IconButton,
  Collapse,
  Chip,
  Stack,
  CardContent,
  Box,
  CardMedia,
  useTheme,
} from '@mui/material';
// components
import Iconify from '../../components/Iconify';
import ItemOptions from './ItemOptions';

// redux
import { useDispatch } from '../../redux/store';
import { addToOrder, removeLastItemOrder } from '../../redux/slices/order';
import { useSelector } from '../../redux/store';

// ----------------------------------------------------------------------

MenuItem.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    description: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.object),
    images: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default function MenuItem({ product }) {
  const { name, price, description, options, tags, _id, images } = product;
  let image = _.get(images, '0');
  if (image) {
    image = `${process.env.REACT_APP_API_BASE_URL}/static/images/${image}`;
  }

  const [isExpanded, setExpand] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  let shouldExpandOptions = options.filter((option) => option.isRequired);
  const isExpandable = !_.isEmpty(options);
  let isInCart = false;
  const { order } = useSelector((state) => {
    return state.order;
  });

  if (order[_id]) {
    isInCart = true;
  }

  const changeOption = (index, _id, name, displayType) => (variants) => {
    selectedOptions[index] = {
      _id,
      name,
      variants,
      displayType,
    };
    setSelectedOptions(selectedOptions);
  };

  const clearOption = (index, _id) => () => {
    delete selectedOptions[index];
    setSelectedOptions(selectedOptions);
  };

  const dispatch = useDispatch();

  function onAdd() {
    const shouldNotExpand = shouldExpandOptions.every((shouldExpandOption) => {
      console.log(selectedOptions);
      return (
        selectedOptions.findIndex(
          (selectedOption) =>
            _.get(selectedOption, '_id') === _.get(shouldExpandOption, '_id')
        ) >= 0
      );
    });
    console.log(shouldNotExpand);
    if (shouldNotExpand) {
      dispatch(
        addToOrder({
          _id: _id,
          name: name,
          options: selectedOptions.filter(Boolean),
          price: price,
          description: description,
        })
      );
      return;
    }
    setExpand(true);
  }

  function onRemove() {
    dispatch(removeLastItemOrder(_id));
  }

  function expand() {
    setExpand(!isExpanded);
  }

  const theme = useTheme();
  return (
    <Card
      sx={{
        position: 'relative',
        boxShadow: '1px 2px 2px 2px rgba(0,0,0,0.08)',
        borderRadius: '16px',
      }}
    >
      <CardContent
        sx={{
          paddingBottom: '4px !important',
        }}
      >
        <Stack direction="column" spacing={1}>
          <Typography
            fontSize={18}
            sx={{
              color: theme.palette.primary.main,
            }}
            fontWeight={900}
          >
            {name}
          </Typography>
          {image && (
            <CardMedia
              component="img"
              alt="Menu item picture"
              height="160px"
              src={image}
              sx={{
                borderRadius: '8px',
              }}
            />
          )}
          <Typography fontSize={13}>{description}</Typography>
          <Box display="flex" flexWrap="wrap" gap={1} sx={{ marginLeft: 0 }}>
            {tags.map((tag, index) => (
              <Chip key={index} label={tag.name} variant="outlined" />
            ))}
          </Box>
        </Stack>

        <Collapse in={isExpanded} timeout={500}>
          <ItemOptions
            options={options}
            changeOption={changeOption}
            clearOption={clearOption}
          />
        </Collapse>

        <CardActions sx={{ justifyContent: 'space-between' }}>
          <Typography>{price} KGS</Typography>
          {isExpandable && (
            <IconButton onClick={expand}>
              <Iconify
                icon={
                  isExpanded
                    ? 'eva:arrowhead-up-outline'
                    : 'eva:arrowhead-down-outline'
                }
                width={24}
                height={24}
              />
            </IconButton>
          )}

          <Stack
            direction="row"
            border={1}
            borderRadius={16}
            borderColor={theme.palette.primary.main}
          >
            <IconButton onClick={onRemove} disabled={!isInCart} color="primary">
              <Iconify icon={'eva:minus-outline'} width={24} height={24} />
            </IconButton>
            <Box
              sx={{ display: 'flex', alignItems: 'center', minWidth: '14px' }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  color: theme.palette.primary.main,
                }}
              >
                {order[_id] ? order[_id].length + 'x' : ''}
              </Typography>
            </Box>

            <IconButton onClick={onAdd} color="primary">
              <Iconify icon={'eva:plus-outline'} width={24} height={24} />
            </IconButton>
          </Stack>
        </CardActions>
      </CardContent>
    </Card>
  );
}
