// @mui
import { Typography, Paper, Stack } from '@mui/material';
// redux
import { useSelector } from '../../redux/store';
import { getTotalPrice } from '../../redux/slices/order';

// ----------------------------------------------------------------------

export default function CartItems() {
  const totalPrice = useSelector(getTotalPrice);
  const { restaurant } = useSelector((state) => {
    return state.restaurant;
  });
  const totalPriceWithCommission = Math.round(
    totalPrice * (restaurant.commission / 100 + 1)
  );

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: '10px',
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Всего:</Typography>
        <Typography variant="body1">{totalPrice} KGS</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Обслуживание:</Typography>
        <Typography variant="body1">{restaurant.commission}%</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">Итого:</Typography>
        <Typography variant="h6">{totalPriceWithCommission} KGS</Typography>
      </Stack>
    </Paper>
  );
}
