import PropTypes from 'prop-types';
// @mui
import { Stack } from '@mui/material';
// components
import ItemOption from './ItemOption';

// ----------------------------------------------------------------------

ItemOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      displayType: PropTypes.string,
      variants: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  changeOption: PropTypes.func.isRequired,
  clearOption: PropTypes.func.isRequired,
};

export default function ItemOptions({ options, changeOption, clearOption }) {
  return (
    <Stack
      mt={1}
      mb={1}
      pb={1}
      pt={1}
      pr={1}
      pl={1}
      spacing={2}
      direction={'column'}
    >
      {options.map((option, index) => (
        <ItemOption
          option={option}
          changeOption={changeOption(
            index,
            option._id,
            option.name,
            option.displayType
          )}
          clearOption={clearOption(index, option._id)}
          key={index}
        />
      ))}
    </Stack>
  );
}
