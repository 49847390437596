import { useEffect, useState } from 'react';
import useMenuNavigation from '../hooks/useMenuNavigation';
import axiosInstance from '../lib/axiosInstance';
// @mui
import { Container, Typography, Stack, Button } from '@mui/material';
// sections
import Orders from '../sections/orders/Orders';

// ----------------------------------------------------------------------

export default function OrdersHistory() {
  const [ordersHistory, setOrdersHistory] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`/v1/orders/public`)
      .then((res) => {
        setOrdersHistory(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const { gotoMenu, gotoLeaveReview } = useMenuNavigation();

  return (
    <Container sx={{ paddingBottom: '140px' }}>
      <Typography variant="h6" align="center" sx={{ padding: '10px' }}>
        Мои заказы
      </Typography>

      <Orders orders={ordersHistory} />
      <Stack
        sx={{
          position: 'fixed',
          bottom: 0,
          left: '10%',
          width: '80%',
          paddingBottom: '10px',
          backgroundColor: 'white'
        }}
        gap={1}
      >
        <Button
          variant="contained"
          fullWidth
          onClick={gotoLeaveReview}
          sx={{
            boxShadow: 'none',
          }}
        >
          Отправить отзыв
        </Button>
        <Button variant="outlined" fullWidth onClick={gotoMenu}>
          <Typography>Назад</Typography>
        </Button>
      </Stack>
    </Container>
  );
}
