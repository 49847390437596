import { useState } from 'react';
// @mui
import { Container, Typography, Button, Stack, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import * as uuid from 'uuid';

import useMenuNavigation from '../hooks/useMenuNavigation';

// redux
import { useSelector, useDispatch } from '../redux/store';
import { getGroupedOrder } from '../redux/slices/order';
import { clearCart } from '../redux/slices/order';

import axiosInstance from '../lib/axiosInstance';

// sections
import CartItems from '../sections/cart/CartItems';
import CartSummary from '../sections/cart/CartSummary';

// ----------------------------------------------------------------------

export default function Cart() {
  const [errorText, setErrorText] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const { gotoMenu, gotoOrders } = useMenuNavigation();
  const dispatch = useDispatch();
  const groupedItems = useSelector(getGroupedOrder);
  const { order } = useSelector((state) => {
    return state.order;
  });


  function serializeOrdersList() {
    const orderGroupId = uuid.v4();
    const optionToMenuItemVariantMap = new Map();

    return Object.values(order).flat().map(menuItem => {
      const stringifiedOptions = JSON.stringify(menuItem.options) + menuItem._id;
      let menuItemVariantId = null;

      if (optionToMenuItemVariantMap.has(stringifiedOptions)) {
        menuItemVariantId = optionToMenuItemVariantMap.get(stringifiedOptions);
      } else {
        menuItemVariantId = uuid.v4();
        optionToMenuItemVariantMap.set(stringifiedOptions, menuItemVariantId);
      }
      return {
        menuItem: menuItem._id,
        menuItemName: menuItem.name,
        menuItemPrice: menuItem.price,
        options: menuItem.options,
        orderGroupId,
        menuItemVariantId
      }
    });
  }

  async function submitOrder() {
    setSubmitLoading(true);
    try {
      await axiosInstance.post('/v1/orders/public', {
        orders: serializeOrdersList()
      });
    } catch (error) {
      if (_.get(error, 'response.status') === 406) {
        console.log(error);
        setErrorText(
          'С одного стола может заказывать только 1 гость. На данном столе есть активный визит и если его владелец Ваш компаньон, то пожалуйста заказывайте через него. Иначе обратитесь к официанту'
        );
        setSubmitLoading(false);
        return;
      }
      if (_.get(error, 'response.status') === 417) {
        console.log(error);
        setErrorText(
          'Нам нужно подтверждение Вашего визита. Дальнейшие заказы станут доступны как только официант примет Ваш первый заказ'
        );
        setSubmitLoading(false);
        return;
      }
      console.log(error);
      setErrorText('Что-то пошло не так');
      setSubmitLoading(false);
      return;
    }

    dispatch(clearCart());
    setSubmitLoading(false);
    gotoOrders();
  }

  return (
    <Container
      sx={{
        paddingBottom: '140px',
      }}
    >
      <Typography variant="h5" align="center" sx={{ padding: '10px' }}>
        Ваш заказ:
      </Typography>

      <CartItems />
      <CartSummary />
      {errorText && (
        <Alert severity="error">
          <Typography>{errorText}</Typography>
        </Alert>
      )}

      <Stack
        sx={{
          position: 'fixed',
          bottom: 0,
          left: '10%',
          width: '80%',
          paddingBottom: '10px',
          backgroundColor: 'white'
        }}
        gap={1}
      >
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={submitOrder}
          disabled={groupedItems.length === 0}
          loading={submitLoading}
          sx={{
            boxShadow: 'none',
          }}
        >
          Потвердить заказ
        </LoadingButton>
        <Button variant="outlined" fullWidth onClick={gotoMenu}>
          <Typography>Назад</Typography>
        </Button>
      </Stack>
    </Container>
  );
}
