export const ORDER_STATUS = {
  pending: {
    meaning: 'В ожидании',
    color: 'grey',
    icon: 'solar:check-read-linear',
  },
  executed: {
    meaning: 'Исполнен',
    color: 'green',
    icon: 'solar:check-read-linear',
  },
  accepted: {
    meaning: 'Принят',
    color: '#008DDA',
    icon: 'solar:check-read-linear',
  },
  declined: {
    meaning: 'Отклонён',
    color: '#A0153E',
    icon: 'material-symbols-light:close',
  },
};

export const ORDER_STATUS_NAME = { PENDING: 'pending', EXECUTED: 'executed', ACCEPTED: 'accepted', DECLINED: 'declined' };