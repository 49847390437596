import { useState } from 'react';
// @mui
import { Autocomplete, TextField } from '@mui/material';

import { useDispatch, useSelector } from '../../../../redux/store';
import { updateMenuItemNameFilter } from '../../../../redux/slices/menuItems';

export default function MenuItemNameFilter() {
  const { allMenuItems } = useSelector((state) => {
    return state.menuItems;
  });
  const { selectedMenuItem } = useSelector((state) => {
    return state.menuItems;
  });

  const dispatch = useDispatch();

  const autocompleteOptions = allMenuItems.map((menuItem) => ({
    label: menuItem.name,
    _id: menuItem._id,
  }));

  function handleMenuItemSelect(event, value) {
    setOpen(false);
    dispatch(updateMenuItemNameFilter(value));
  }

  const [open, setOpen] = useState(false);

  return (
    <>
      <Autocomplete
        sx={{
          paddingTop: '4px',
        }}
        size={'medium'}
        value={selectedMenuItem}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={autocompleteOptions}
        disableCloseOnSelect
        blurOnSelect
        fullWidth
        isOptionEqualToValue={(option, value) => option._id === value._id}
        renderInput={(params) => (
          <TextField {...params} label="Поиск по названию" />
        )}
        onChange={handleMenuItemSelect}
        getOptionKey={(option) => option._id}
      />
    </>
  );
}
