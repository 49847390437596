// @mui
import { Slider } from '@mui/material';

import displayTypesInterface from './displayTypesInterface';

SingleChoiceSlider.propTypes = displayTypesInterface;

export default function SingleChoiceSlider(props) {
  const { handler, values, clear } = props;
  const options = values
    .map((value) => ({
      label: value,
      value: parseInt(value),
    }))
    .sort((a, b) => a.value - b.value);

  function localHandler(event, value) {
    if (!value) {
      return clear();
    }
    return handler([value]);
  }

  function valuetext(value) {
    return value;
  }

  return (
    <Slider
      track={false}
      valueLabelDisplay="auto"
      step={1}
      getAriaValueText={valuetext}
      marks={options}
      min={options[0].value}
      max={options[options.length - 1].value}
      onChange={localHandler}
      size="small"
    />
  );
}
