import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axiosInstance from '../lib/axiosInstance';
// @mui
import { Container, Divider, Box, Alert, Typography } from '@mui/material';
// sections
import MenuItems from '../sections/menu/MenuItems';
import FilterComponent from '../sections/menu/FilterComponent';
import Footer from '../sections/footer/Footer';

import { useDispatch, useSelector } from '../redux/store';
import { initMenuItems } from '../redux/slices/menuItems';
import { initCategories, initTags } from '../redux/slices/filters';
import { initRestaurant } from '../redux/slices/restaurant';
import MakeAnOrderButton from '../sections/menu/MakeAnOrderButton';

// ----------------------------------------------------------------------

export default function Menu() {
  const [errorText, setErrorText] = useState('');
  const { restaurantId, tableId } = useParams();

  const dispatch = useDispatch();

  const { allMenuItems } = useSelector((state) => {
    return state.menuItems;
  });

  useEffect(() => {
    if (tableId && restaurantId) {
      if (!_.isEmpty(allMenuItems)) {
        return;
      }
      try {
        Promise.all([
          axiosInstance
            .get(`/v1/menu-items/public/${restaurantId}`)
            .then((res) => {
              dispatch(initMenuItems(res.data));
            }),
          axiosInstance
            .get(`/v1/item-types/public/${restaurantId}`)
            .then((res) => {
              dispatch(initCategories(res.data));
            }),
          axiosInstance.get(`/v1/tags/public/${restaurantId}`).then((res) => {
            dispatch(initTags(res.data));
          }),
          axiosInstance
            .get(`/v1/restaurants/public/${restaurantId}`)
            .then((res) => {
              dispatch(initRestaurant(res.data));
            }),
        ]);
      } catch (error) {
        setErrorText(
          'Не получилось получить данные с сервера, пожалуйста перезагрузите страницу'
        );
        dispatch(initMenuItems([]));
        dispatch(initCategories([]));
        dispatch(initTags([]));
        dispatch(initRestaurant({}));
      }
    } else {
      dispatch(initMenuItems([]));
      dispatch(initCategories([]));
      dispatch(initTags([]));
      dispatch(initRestaurant({}));
    }
  }, [tableId, restaurantId, dispatch, allMenuItems]);

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Container sx={{ paddingBottom: '26px' }}>
        <FilterComponent></FilterComponent>
        <Box sx={{ paddingBottom: '16px' }}>
          <Divider variant="middle" />
        </Box>
        {errorText && (
          <Alert severity="error">
            <Typography>{errorText}</Typography>
          </Alert>
        )}

        <MenuItems />

        <Box
          sx={{
            position: 'fixed',
            bottom: '32px',
            right: '32px',
            zIndex: 1000, // Ensure it appears above other content
          }}
        >
          <MakeAnOrderButton />
        </Box>
        
      </Container>
      <Footer />
    </Box>
  );
}
