import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
// redux
import { useSelector } from '../../redux/store';
//
import MenuItem from './MenuItem';

// ----------------------------------------------------------------------

MenuItems.propTypes = {
  loading: PropTypes.bool,
};

export default function MenuItems({ loading }) {
  const { filteredMenuItems } = useSelector((state) => {
    return state.menuItems;
  });
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 1.6,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
        },
      }}
    >
      {filteredMenuItems.map((product, index) => (
        <MenuItem key={index} product={product} />
      ))}
    </Box>
  );
}
