import { useNavigate, useParams } from 'react-router-dom';

export default function useMenuNavigation() {
  const navigate = useNavigate();
  const { tableId, restaurantId } = useParams();

  const mainPath = `/restaurant/${restaurantId}/table/${tableId}`;

  return {
    gotoMenu: () => navigate(mainPath),
    gotoCart: () => navigate(mainPath + '/cart'),
    gotoOrders: () => navigate(mainPath + '/orders'),
    gotoLeaveReview: () => navigate(mainPath + '/review'),
    goBack: () => navigate(-1),
  };
}
