// @mui
import { Button, Typography } from '@mui/material';

import useMenuNavigation from '../../hooks/useMenuNavigation';

// ----------------------------------------------------------------------

export default function MakeAnOrderButton() {
  const { gotoOrders } = useMenuNavigation();

  return (
    <Button
      variant="outlined"
      onClick={() => gotoOrders()}
      sx={{
        textTransform: 'none',
      }}
    >
      <Typography>Мои заказы</Typography>
    </Button>
  );
}
