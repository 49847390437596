import PropTypes from 'prop-types';
// @mui
import { Typography, Stack, Box, useTheme } from '@mui/material';
import DisplayTypesComponent from './components/displayTypes';

// ----------------------------------------------------------------------

ItemOption.propTypes = {
  changeOption: PropTypes.func.isRequired,
  option: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    displayType: PropTypes.string,
    variants: PropTypes.arrayOf(PropTypes.string),
    isRequired: PropTypes.bool,
  }),
  clearOption: PropTypes.func.isRequired,
};

export default function ItemOption({ option, changeOption, clearOption }) {
  const theme = useTheme();
  const { name, displayType, variants, isRequired } = option;
  return (
    <Box>
      <Typography variant="body2">
        {name}{' '}
        {isRequired ? (
          <b style={{ color: theme.palette.primary.main }}>(Обязательное поле)</b>
        ) : (
          ''
        )}
      </Typography>
      <Stack direction="column" mt={1}>
        <DisplayTypesComponent
          displayType={displayType}
          values={variants}
          name={name}
          handler={changeOption}
          clear={clearOption}
          isRequired={isRequired}
        ></DisplayTypesComponent>
      </Stack>
    </Box>
  );
}
