import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  allMenuItems: [],
  filteredMenuItems: [],
  selectedCategory: null, // name and _id
  selectedTags: [],
  selectedMenuItem: null, // name and _id
};

function filterByCategory(menuItems, selectedCategory) {
  if (_.isEmpty(selectedCategory)) {
    return menuItems;
  }
  return menuItems.filter((item) => item.category._id === selectedCategory._id);
}

function filterByTags(menuItems, selectedTags) {
  if (_.isEmpty(selectedTags)) {
    return menuItems;
  }
  return menuItems.filter(
    (item) => _.differenceBy(selectedTags, item.tags, '_id').length === 0
  );
}

function filterByMenuItemName(menuItems, selectedMenuItem) {
  if (_.isEmpty(selectedMenuItem)) {
    return menuItems;
  }
  return menuItems.filter(
    (item) => item._id === selectedMenuItem._id
  );
}

function filterMenuItems(allMenuItems, selectedCategory, selectedTags, selectedMenuItem) {
  if (selectedMenuItem) {
    return filterByMenuItemName(allMenuItems, selectedMenuItem);
  }
  const filteredByCategoryMenuItems = filterByCategory(
    allMenuItems,
    selectedCategory
  );
  return filterByTags(filteredByCategoryMenuItems, selectedTags);
}

const slice = createSlice({
  name: 'menuItems',
  initialState: initialState,
  reducers: {
    initMenuItems: (state, action) => {
      action.payload.forEach((item) => {
        item.category = { ...item.itemType };
        delete item.itemType;
        state.allMenuItems.push(item);
        state.filteredMenuItems.push(item);
      });
    },
    updateCategoryFilter: (state, action) => {
      state.selectedCategory = action.payload;
      state.selectedMenuItem = null;
      state.filteredMenuItems = filterMenuItems(
        state.allMenuItems,
        state.selectedCategory,
        state.selectedTags,
        state.selectedMenuItem
      );
    },
    updateTagsFilter: (state, action) => {
      state.selectedTags = action.payload;
      state.selectedMenuItem = null;
      state.filteredMenuItems = filterMenuItems(
        state.allMenuItems,
        state.selectedCategory,
        state.selectedTags,
        state.selectedMenuItem
      );
    },
    updateMenuItemNameFilter: (state, action) => {
      state.selectedMenuItem = action.payload;
      state.selectedCategory = null;
      state.selectedTags = [];
      state.filteredMenuItems = filterMenuItems(
        state.allMenuItems,
        state.selectedCategory,
        state.selectedTags,
        state.selectedMenuItem
      );
    }
  },
});

// Actions
export const { initMenuItems, updateCategoryFilter, updateTagsFilter, updateMenuItemNameFilter } =
  slice.actions;

// Reducer
export default slice.reducer;
