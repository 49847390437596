// @mui
import { Typography, Paper, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export default function Footer() {
  return (
    <Paper
      sx={{
        pt: '8px',
        pb: '8px',
        boxShadow: 'none',
        width: '100%',
        backgroundColor: '#2D9596',
        border: 'none',
        borderRadius: 0,
      }}
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Typography fontSize={12} color="white">DayarMenu 2024</Typography>
        <Typography fontSize={12} color="white">dayarmenu@gmail.com</Typography>
      </Stack>
    </Paper>
  );
}
