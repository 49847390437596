import PropTypes from 'prop-types';
// @mui
import { Typography, IconButton, Stack, Grid } from '@mui/material';
// components
import Iconify from '../../components/Iconify';

// redux
import { useDispatch } from '../../redux/store';
import { addToOrder, removeSpecificItem } from '../../redux/slices/order';

// ----------------------------------------------------------------------

CartItem.propTypes = {
  cartItem: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        displayType: PropTypes.string,
        variants: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
      })
    ),
    description: PropTypes.string,
    amount: PropTypes.number,
  }).isRequired,
};

export default function CartItem({ cartItem }) {
  const { _id, name, options, price, description, cartItemIndex } = cartItem;
  const dispatch = useDispatch();

  function onAdd() {
    dispatch(
      addToOrder({
        _id: _id,
        name: name,
        options: options,
        price: price,
        description: description,
      })
    );
  }

  function onRemove() {
    dispatch(
      removeSpecificItem({
        _id: _id,
        index: cartItemIndex,
      })
    );
  }
  return (
    <Stack direction="column" width="100%" padding={0}>
      <Grid container spacing={1} columns={14} wrap="nowrap">
        <Grid item xs={2}>
          <Typography align="center">{cartItem.amount}x</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography align="left">{cartItem.name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography align="right">{cartItem.totalPrice} KGS</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} columns={14} wrap="nowrap">
        <Grid item xs={2} padding={0}>
          <IconButton onClick={onRemove} color="primary">
            <Iconify icon={'eva:minus-outline'} width={24} height={24} />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography align="left" variant="subtitle2" sx={{ color: 'gray' }}>
            {cartItem.options
              .map((option) => {
                return `${option.name} (${option.variants.join(', ')})`;
              })
              .join(', ')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={onAdd} color="primary">
            <Iconify icon={'eva:plus-outline'} width={24} height={24} />
          </IconButton>
        </Grid>
      </Grid>
    </Stack>
  );
}
