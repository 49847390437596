import { useNavigate, useLocation } from 'react-router-dom';
// @mui
import { Fab } from '@mui/material';

// redux
import { useSelector } from '../../redux/store';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function MakeAnOrderButton() {
  const { order } = useSelector((state) => {
    return state.order;
  });

  let sum = 0;
  Object.keys(order).forEach((key) => {
    sum += order[key].reduce((acc, item) => {
      return acc + item.price;
    }, 0);
  });

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Fab
      variant="circular"
      onClick={() => navigate(location.pathname + '/cart')}
      sx={{
        textTransform: 'none',
        display: 'flex',
        flexDirection: 'column',
        width: '64px',
        height: '64px'
      }}
      color="primary"
      disabled={!sum}
    >
      <Iconify
        icon={'f7:cart-badge-plus'}
        width={40}
        height={40}
      />
    </Fab>
  );
}
