// @mui
import { Autocomplete, TextField } from '@mui/material';

import displayTypesInterface from './displayTypesInterface';

SingleChoiceDropdownAutocomplete.propTypes = displayTypesInterface;

export default function SingleChoiceDropdownAutocomplete(props) {
  const { handler, values, clear } = props;
  const autocompleteOptions = values.map((value) => ({
    label: value,
  }));

  function localHandler(event, value) {
    if (!value) {
      return clear();
    }
    return handler([value.label]);
  }

  return (
    <Autocomplete
      fullWidth
      blurOnSelect
      isOptionEqualToValue={(option, value) => option.label === value.label}
      size={'small'}
      options={autocompleteOptions}
      renderInput={(params) => (
        <TextField {...params} label="Выберите значение" />
      )}
      onChange={localHandler}
    />
  );
}
