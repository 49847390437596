// @mui
import { List, ListItem, Divider } from '@mui/material';
// redux
import { useSelector } from '../../redux/store';
import { getGroupedOrder } from '../../redux/slices/order';
//
import CartItem from './CartItem';

// ----------------------------------------------------------------------

export default function CartItems() {
  const groupedItems = useSelector(getGroupedOrder);
  return (
    <List dense={false}>
      {groupedItems.map((cartItem, index) => (
        <div key={index}>
          <ListItem key={index}>
            <CartItem cartItem={cartItem} />
          </ListItem>
          <Divider variant="middle" />
        </div>
      ))}
    </List>
  );
}
