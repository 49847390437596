import PropTypes from 'prop-types';
// @mui
import {
  Typography,
  Stack,
  List,
  ListItem,
  Divider,
  Grid,
  Box
} from '@mui/material';
import Iconify from '../../components/Iconify';

import { ORDER_STATUS } from './constants';

// ----------------------------------------------------------------------

OrderGroup.propTypes = {
  order: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  groupedMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      menuItemName: PropTypes.string,
      amount: PropTypes.number,
      menuItemPrice: PropTypes.number,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          variants: PropTypes.arrayOf(PropTypes.string),
        })
      ),
    })
  ),
};

export default function OrderGroup({ order, groupedMenuItems }) {
  return (
    <>
      <List dense={false} sx={{ paddingBottom: '0px' }}>
        {groupedMenuItems.map((detail, index) => (
          <div key={index}>
            <ListItem key={index}>
              <Stack sx={{ width: '100%' }}>
                <Grid container spacing={1} columns={28} wrap="nowrap">
                  <Grid item xs={3}>
                    <Typography align="left">{detail.amount}x</Typography>
                  </Grid>
                  <Grid item xs={14}>
                    <Typography align="left">{detail.menuItemName}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography align="right">
                      {detail.menuItemPrice * detail.amount} KGS
                    </Typography>
                  </Grid>
                  <Grid item xs={3} pt={0.5} pl={1}>
                    <Iconify
                      icon={ORDER_STATUS[detail.status].icon}
                      width={30}
                      height={30}
                      sx={{
                        color: ORDER_STATUS[detail.status].color
                      }}
                    />
                  </Grid>
                  
                </Grid>
                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'gray' }}>
                    {detail.options
                      .map((option) => {
                        return `${option.name} (${option.variants.join(', ')})`;
                      })
                      .join(', ')}
                  </Typography>
                </Box>
              </Stack>
            </ListItem>
            {(index !== groupedMenuItems.length - 1) && <Divider variant="middle" />}
          </div>
        ))}
      </List>
    </>
  );
}
