// import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import CaptchaGuard from '../guards/CaptchaGuard';
// layouts
// import MainLayout from '../layouts/main';
import Menu from '../pages/Menu';
import Cart from '../pages/Cart';
import OrdersHistory from '../pages/OrdersHistory';
import LeaveReview from '../pages/LeaveReview';

// components
// import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

// const Loadable = (Component) => (props) => {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   // const { pathname } = useLocation();

//   return (
//     <Suspense
//     // fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
//     >
//       <Component {...props} />
//     </Suspense>
//   );
// };

export default function Router() {
  return useRoutes([
    {
      path: '/restaurant/:restaurantId/table/:tableId',
      element: (
        <CaptchaGuard>
          <Menu />
        </CaptchaGuard>
      ),
    },
    {
      path: '/restaurant/:restaurantId/table/:tableId/cart',
      element: (
        <CaptchaGuard>
          <Cart />
        </CaptchaGuard>
      ),
    },
    {
      path: '/restaurant/:restaurantId/table/:tableId/orders',
      element: (
        <CaptchaGuard>
          <OrdersHistory />
        </CaptchaGuard>
      ),
    },
    {
      path: '/restaurant/:restaurantId/table/:tableId/review',
      element: (
        <CaptchaGuard>
          <LeaveReview />
        </CaptchaGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
