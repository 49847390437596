import PropTypes from 'prop-types';

const displayTypesInterface = {
  handler: PropTypes.func.isRequired, // argument must be an array
  values: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  clear: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};

export default displayTypesInterface;
