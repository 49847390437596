// @mui
import { Stack, Paper, Typography } from '@mui/material';
//
import { useSelector } from '../../redux/store';
import OrderGroup from './OrderGroup';
import { ORDER_STATUS_NAME } from './constants';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
dayjs.locale('ru');

// ----------------------------------------------------------------------

export default function Orders({ orders }) {
  const groupedByOrderGroup = orders.reduce((acc, order) => {
    if (acc[order.orderGroupId]) {
      acc[order.orderGroupId].push(order);
    } else {
      acc[order.orderGroupId] = [order];
    }

    return acc;
  }, {});
  const arrGroupedByOrderGroup = Object.values(groupedByOrderGroup);

  const groupedByMenuItemVariantAmountAndStatus = arrGroupedByOrderGroup.map(orderGroupMenuItems => {
    const groupedByMenuItemVariantWithAmountAndStatus = orderGroupMenuItems.reduce((acc, order) => {
      const key = order.menuItemVariantId + order.status;
      if (acc[key]) {
        acc[key].amount++;
      } else {
        acc[key] = order;
        acc[key].amount = 1;
      }

      return acc;
    }, {});

    return Object.values(groupedByMenuItemVariantWithAmountAndStatus);
  });

  const totalPrice = orders.reduce((acc, order) => {
    if (order.status === ORDER_STATUS_NAME.DECLINED) {
      return acc;
    }
    return acc + order.menuItemPrice;
  }, 0)
  const { restaurant } = useSelector((state) => {
    return state.restaurant;
  });
  const totalPriceWithCommission = Math.round(
    totalPrice * (restaurant.commission / 100 + 1)
  );

  return (
    <Stack spacing={2}>
      {arrGroupedByOrderGroup.map((orderGroupMenuItems, index) => (
        <div key={index}>
          <Typography textAlign='center' fontSize={14}>
            Время заказа: {dayjs(orderGroupMenuItems[0].createdAt).format('HH:mm')}
          </Typography>
          <Paper key={index} variant="outlined" sx={{ position: 'relative' }}>
            <OrderGroup order={orderGroupMenuItems[0]} groupedMenuItems={groupedByMenuItemVariantAmountAndStatus[index]} />
          </Paper>
        </div>
        
      ))}
      <Paper
        variant="outlined"
        sx={{
          padding: '10px',
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1">Всего:</Typography>
          <Typography variant="body1">{totalPrice} KGS</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1">Обслуживание:</Typography>
          <Typography variant="body1">{restaurant.commission}%</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Итого:</Typography>
          <Typography variant="h6">{totalPriceWithCommission} KGS</Typography>
        </Stack>
      </Paper>
    </Stack>
  );
}
