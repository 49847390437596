import { combineReducers } from 'redux';
// import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
// slices
import orderReducer from './slices/order';
import menuItemsReducer from './slices/menuItems';
import filtersReducer from './slices/filters';
import restaurantReducer from './slices/restaurant';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['order', 'restaurant'],
};

const rootReducer = combineReducers({
  order: orderReducer,
  menuItems: menuItemsReducer,
  filtersReducer: filtersReducer,
  restaurant: restaurantReducer,
});

export { rootPersistConfig, rootReducer };
