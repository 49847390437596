import { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button,
  Typography,
  Container,
  Card,
  CardContent,
  Alert,
  Box,
  Modal,
  useTheme,
} from '@mui/material';
import Turnstile from "react-turnstile";
import { useParams } from 'react-router-dom';
import axiosInstance from '../lib/axiosInstance';

import { useDispatch } from '../redux/store';
import { clearCart } from '../redux/slices/order';

export default function ReCaptcha({ setTokenValid }) {
  const [captchaToken, setCaptchaToken] = useState(null);
  const [errorText, setErrorText] = useState('');
  const { restaurantId, tableId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCart());
  }, [dispatch]);

  const handleCaptchaChange = (value) => {
    console.log('token', value);
    setCaptchaToken(value);
  };

  const handleSubmit = async () => {
    // setModalOpen(false);

    try {
      await axiosInstance.post('/v1/credentials/send', {
        token: captchaToken,
        restaurant: restaurantId,
        table: tableId
      });
      setTokenValid(true);
    } catch (error) {
      if (
        _.get(error, 'response.status') === 403
      ) {
        setErrorText(
          'Вы не прошли проверку ReCaptcha. Пожалуйста, перезагрузите страницу и попробуйте снова'
        );
        return;
      }
      setErrorText('Что-то пошло не так');
      console.log(error);
    }

    // let latitude = null;
    // let longitude = null;
    // navigator.geolocation.getCurrentPosition(
    //   async (position) => {
    //     latitude = position.coords.latitude;
    //     longitude = position.coords.longitude;
    //     console.log(position.coords);
    //     console.log(captchaToken);

    //     try {
    //       await axiosInstance.post('/v1/credentials/send', {
    //         token: captchaToken,
    //         restaurant: restaurantId,
    //         table: tableId,
    //         location: {
    //           latitude: latitude,
    //           longitude: longitude,
    //         },
    //       });
    //       setTokenValid(true);
    //     } catch (error) {
    //       if (
    //         _.get(error, 'response.status') === 403 &&
    //         _.get(error, 'response.data.reason') === 'location'
    //       ) {
    //         setErrorText(
    //           'Наша система говорит, что Вы не находитесь в заведении. Если Вы действительно находитесь в заведении, то пожалуйста попробуйте перезагрузить страницу'
    //         );
    //         return;
    //       }
    //       setErrorText('Что-то пошло не так');
    //       console.log(error);
    //     }
    //   },
    //   (error) => {
    //     console.error(error);
    //     setErrorText(
    //       'Вы не дали доступ к геолокации, пожалуйста дайте разрешение в настройках телефона'
    //     );
    //   }
    // );
  };

  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);

  // function openModal() {
  //   setModalOpen(true);
  // }

  function closeModal() {
    setModalOpen(false);
  }

  return (
    <Container>
      <Box
        component="img"
        sx={{
          marginTop: '20px',
          width: '60%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        alt="The house from the offer."
        src="/logo-for-web.png"
      />

      <Card
        sx={{
          marginTop: '20px',
          backgroundColor: theme.palette.menuItemBlock.main,
          boxShadow: 'none',
          borderRadius: '16px',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div>
            <Typography>Дорогой Гость,</Typography>
            <br />
            <Typography>В нашем онлайн меню, Вы можете <strong>сделать заказ</strong> или вызвать официанта, если Вам нужна помощь.</Typography>
            <br />
            <Typography>Но перед этим нам нужно убедиться, что Вы не робот. Пожалуйста, пройдите проверку ниже:</Typography>
          </div>
          <Turnstile
            sitekey={process.env.REACT_APP_CF_TURNSTILE_SITE_KEY}
            onVerify={handleCaptchaChange}
          />
          <Button variant="contained" disabled={!captchaToken} onClick={handleSubmit}>
            Войти в меню
          </Button>
          {errorText && (
            <Alert severity="error">
              <Typography>{errorText}</Typography>
            </Alert>
          )}
        </CardContent>
      </Card>
      <Modal
        open={modalOpen}
        onClose={() => closeModal()}
      >
        <Box
          sx={{
            width: '80%',
            backgroundColor: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '16px'
          }}
        >
          <h2>Важно!</h2>
          <p>
            Сейчас Ваш браузер попросит доступ к геолокации, пожалуйста предварительно включите GPS на телефоне 
          </p>
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            sx={{
              boxShadow: 'none',
            }}
          >
            Понятно
          </Button>
        </Box>
      </Modal>
    </Container>
  );
}
