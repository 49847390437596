// @mui
import { Tabs, Tab, Typography, Stack, Button } from '@mui/material';

import Iconify from '../../../../components/Iconify';

import { useDispatch, useSelector } from '../../../../redux/store';
import { updateCategoryFilter } from '../../../../redux/slices/menuItems';

// ----------------------------------------------------------------------

export default function CategoriesFilter(props) {
  const { allCategories } = useSelector((state) => {
    return state.filtersReducer;
  });
  const { selectedCategory } = useSelector((state) => {
    return state.menuItems;
  });

  const value= selectedCategory ? selectedCategory._id : null;

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    update(newValue);
  };

  const handleTabClick = (newValue) => {
    update(newValue);
  };

  const update = (newValue) => {
    const categoryValue = value === newValue ? null : newValue;

    const categoryIndex = allCategories.findIndex(
      (category) => category._id === categoryValue
    );
    dispatch(
      allCategories[categoryIndex]
        ? updateCategoryFilter(allCategories[categoryIndex])
        : updateCategoryFilter(null)
    );
  };

  const CustomScrollButton = ({ direction, onClick, disabled }) => (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '40px',
        padding: 0,
        margin: 0
      }}
      color="primary"
    >
      {direction === 'left' ?
        <Iconify icon={'mingcute:left-fill'} width={26} height={26} /> :
        <Iconify icon={'mingcute:right-fill'} width={26} height={26} />}
    </Button>
  );

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons={true}
      allowScrollButtonsMobile={true}
      sx={{
        marginTop: '6px !important'
      }}
      ScrollButtonComponent={(props) => <CustomScrollButton {...props} />}
    >
      <Tab
        label={
          <Stack direction="row">
            <Iconify
              icon={'system-uicons:grid-squares'}
              width={24}
              height={24}
            />
            <Typography fontSize={16} sx={{ paddingTop: '1.5px' }}>Все</Typography>
          </Stack>
        }
        value={null}
      />
      {allCategories.map((category, index) => (
        <Tab
          key={index}
          label={category.name}
          value={category._id}
          sx={{
            fontSize: '16px'
          }}
          onClick={() => handleTabClick(category._id)}
        />
      ))}
    </Tabs>
  );
}
