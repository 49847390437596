import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  restaurant: {},
};

const slice = createSlice({
  name: 'restaurant',
  initialState: initialState,
  reducers: {
    initRestaurant: (state, action) => {
      state.restaurant = action.payload;
    },
  },
});

// Actions
export const { initRestaurant } = slice.actions;

// Reducer
export default slice.reducer;
