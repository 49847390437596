import { useState } from 'react';
import _ from 'lodash';
// @mui
import { Autocomplete, TextField, Checkbox, Chip, Box } from '@mui/material';

import Iconify from '../../../../components/Iconify';

import displayTypesInterface from './displayTypesInterface';

MultipleChoiceDropdownAutocomplete.propTypes = displayTypesInterface;

export default function MultipleChoiceDropdownAutocomplete(props) {
  const { handler, values, clear } = props;

  const [selectedValues, setSelectedValues] = useState([]);
  const [value, setValue] = useState([]);

  const autocompleteOptions = values.map((value, index) => ({
    label: value,
    index: index,
  }));

  function localHandler(event, values) {
    if (_.isEmpty(values)) {
      return clear();
    }
    return handler(values.map((value) => value.label));
  }

  function handleSelectedValuesChange(event, values) {
    values.sort((a, b) => a.index - b.index);
    setValue(values);
    setSelectedValues(values);
    setOpen(false);
    localHandler(event, values);
  }

  const [open, setOpen] = useState(false);

  const handleDelete = (option) => () => {
    const values = value.filter((value) => value.index !== option.index);
    setValue(values);
    setSelectedValues(values);
    localHandler({}, values);
  };

  return (
    <>
      <Autocomplete
        multiple
        size={'small'}
        value={value}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        renderTags={(value, getTagProps) => {
          return <></>;
        }}
        options={autocompleteOptions}
        disableCloseOnSelect
        blurOnSelect
        fullWidth
        limitTags={1}
        isOptionEqualToValue={(option, value) => option.index === value.index}
        renderInput={(params) => (
          <TextField {...params} label="Выберите значения" />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={
                <Iconify
                  icon="mdi:checkbox-blank-outline"
                  width={24}
                  height={24}
                />
              }
              checkedIcon={
                <Iconify icon="mdi:checkbox-marked" width={24} height={24} />
              }
              checked={selected}
            />
            {option.label}
          </li>
        )}
        onChange={handleSelectedValuesChange}
      />
      <Box mt={2} display="flex" flexWrap="wrap" gap={1} sx={{ marginLeft: 0 }}>
        {selectedValues.map((option, index) => (
          <Chip
            key={index}
            label={option.label}
            onDelete={handleDelete(option)}
          />
        ))}
      </Box>
    </>
  );
}
