import axios from 'axios';
import _ from 'lodash';
import { isMatch } from 'matcher';
import mockedApi from './mocked.api.json';

let instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export const setupResponseInterceptor = (navigate) => {
  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 403 && _.get(error, 'response.data.message') === 'The access token is not identified in the system') {
        navigate(0);
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  )
}

if (process.env.REACT_APP_USE_MOCKED_API === 'true') {
  instance = {
    get: function (str) {
      const res = Object.keys(mockedApi).filter((key) => isMatch(str, key))[0];
      return Promise.resolve({
        data: mockedApi[res],
      });
    },
    post: function (str) {
      if (str === '/v1/credentials/send') {
        document.cookie = 'access-token=123';
      }
      if (str === '/v1/orders/public') {
        return true;
      }
    },
  };
}

export default instance;
