import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axiosInstance from '../lib/axiosInstance';

import ReCaptcha from '../pages/ReCaptcha';
import MovedWithExistingVisitPage from '../pages/MovedWithExistingVisitPage';
import Cookies from 'js-cookie';

export default function CaptchaGuard({ children }) {
  const { restaurantId, tableId } = useParams();
  const [tokenValid, setTokenValid] = useState(false);
  const [visitExists, setVisitExists] = useState(false);
  const accessToken = Cookies.get('access-token');

  useEffect(() => {
    axiosInstance
      .post(`/v1/credentials/check`, {
        restaurant: restaurantId,
        table: tableId,
      })
      .then((res) => {
        setTokenValid(true);
      })
      .catch((error) => {
        console.error(error);
        if (_.get(error, 'response.status') === 403) {
          setTokenValid(false);
          setVisitExists(false);
          return;
        }
        if (_.get(error, 'response.status') === 417) {
          setVisitExists(_.get(error, 'response.data.visitExists'));
          return;
        }
      });
  }, [restaurantId, tableId, setTokenValid, setVisitExists, accessToken]);

  if (!tokenValid && !visitExists) {
    return <ReCaptcha setTokenValid={setTokenValid} />;
  }
  if (!tokenValid && visitExists) {
    return <MovedWithExistingVisitPage />;
  }

  return children;
}
