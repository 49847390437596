import { useState } from 'react';
import './App.css';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { setupResponseInterceptor } from './lib/axiosInstance';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

function App() {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  if (!isLoaded) {
    setIsLoaded(true);
    setupResponseInterceptor(navigate);
  }
  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
}

export default App;
