import { createSlice, createSelector } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  order: {},
};

const slice = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {
    addToOrder: (state, action) => {
      console.log(action.payload);
      const { _id } = action.payload;
      if (state.order[_id]) {
        const itemCollection = state.order[_id];
        itemCollection.push(action.payload);
        return;
      }
      state.order[_id] = [action.payload];
    },
    removeLastItemOrder: (state, action) => {
      const _id = action.payload;
      if (!state.order[_id]) {
        return;
      }
      const itemCollection = state.order[_id];
      if (itemCollection.length === 1) {
        delete state.order[_id];
        return;
      }
      itemCollection.pop();
    },
    removeSpecificItem: (state, action) => {
      const { _id, index } = action.payload;
      if (!state.order[_id]) {
        return;
      }
      const itemCollection = state.order[_id];
      if (itemCollection.length === 1) {
        delete state.order[_id];
        return;
      }
      itemCollection.splice(index, 1);
    },
    clearCart: (state, action) => {
      Object.keys(state.order).forEach((key) => {
        delete state.order[key];
      });
    },
  },
});

export const getGroupedOrder = createSelector(
  (state) => state.order,
  ({ order }) => {
    const groupedItems = Object.keys(order).map((menuItemId) => {
      const groupedItemsByOptions = order[menuItemId].reduce(
        (acc, menuItem, index) => {
          const menuItemCopy = { ...menuItem };
          const menuItemOptions = JSON.stringify(menuItemCopy.options);
          if (!acc[menuItemOptions]) {
            acc[menuItemOptions] = menuItemCopy;
            acc[menuItemOptions].amount = 1;
            acc[menuItemOptions].totalPrice = menuItemCopy.price;
            acc[menuItemOptions].cartItemIndex = index;
            return acc;
          }
          acc[menuItemOptions].amount++;
          acc[menuItemOptions].totalPrice += menuItemCopy.price;
          acc[menuItemOptions].cartItemIndex = index;
          return acc;
        },
        {}
      );
      return Object.values(groupedItemsByOptions);
    });
    return [].concat.apply([], groupedItems);
  }
);

export const getTotalPrice = createSelector(
  (state) => state.order,
  ({ order }) => {
    return Object.values(order).reduce((acc, items) => {
      return (
        acc +
        items.reduce((acc, item) => {
          return acc + item.price;
        }, 0)
      );
    }, 0);
  }
);

// Reducer
export default slice.reducer;

// Actions
export const {
  addToOrder,
  removeLastItemOrder,
  removeSpecificItem,
  clearCart,
} = slice.actions;
