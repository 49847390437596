import PropTypes from 'prop-types';
// @mui
import { Alert } from '@mui/material';

import SingleChoiceDropdownAutocomplete from './singleChoiceDropdownAutocomplete';
import MultipleChoiceDropdownAutocomplete from './multipleChoiceDropdownAutocomplete';
import singleChoiceSlider from './singleChoiceSlider';
import toggler from './toggler';

DisplayTypesComponent.propTypes = {
  handler: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  displayType: PropTypes.string,
  name: PropTypes.string,
  clear: PropTypes.func.isRequired,
};

const displayTypes = {
  singleChoiceDropdownAutocomplete: SingleChoiceDropdownAutocomplete,
  multipleChoiceDropdownAutocomplete: MultipleChoiceDropdownAutocomplete,
  singleChoiceSlider: singleChoiceSlider,
  toggler: toggler,
};

export default function DisplayTypesComponent(props) {
  const { handler, values, displayType, name, clear } = props;

  if (!displayTypes[displayType]) {
    return <Alert severity="warning">Такой опции нет</Alert>;
  }

  const Component = displayTypes[displayType];

  return (
    <Component
      handler={handler}
      values={values}
      name={name}
      clear={clear}
    ></Component>
  );
}
