import { useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import {
  Container,
  Typography,
  Button,
  Stack,
  Alert,
  TextareaAutosize,
  Rating,
  Snackbar,
} from '@mui/material';
import _ from 'lodash';

import useMenuNavigation from '../hooks/useMenuNavigation';

import axiosInstance from '../lib/axiosInstance';

// ----------------------------------------------------------------------

export default function LeaveReview() {
  const [errorText, setErrorText] = useState('');
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const { restaurantId, tableId } = useParams();
  const { goBack, gotoMenu } = useMenuNavigation();
  const [isSnackbarOpen, setSnackbarOpenState] = useState(false);

  async function submitReview() {
    if (!rating || comment.length === 0) {
      setErrorText('Пожалуйста поставьте оценку и напишите отзыв');
      return;
    }
    try {
      await axiosInstance.post('/v1/reviews/public', {
        table: tableId,
        restaurant: restaurantId,
        rating,
        comment,
      });
      setSnackbarOpenState(true);
      setErrorText('');
      gotoMenu();
    } catch (error) {
      if (_.get(error, 'response.status') === 417) {
        console.log(error);
        setErrorText(
          'Ваш первый заказ еще в обработке, пожалуйста поторопите официанта чтобы он принял заказ'
        );
        return;
      }
      console.log(error);
      setErrorText('Что-то пошло не так');
      return;
    }
  }

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  return (
    <Container
      sx={{
        paddingBottom: '140px',
      }}
    >
      <Typography variant="h5" align="center" sx={{ padding: '5px' }}>
        Отправить отзыв:
      </Typography>
      <Typography variant="body2" align="center" sx={{ padding: '5px' }}>
        Вы пользуетесь бета версией продукта. Мы будем признательны, если Вы
        напишете свои предложения по улучшению. Мы обязательно учтем их при
        разработке следующей версии
      </Typography>

      <Stack alignItems="center" mb={2} mt={2} gap={3}>
        <Rating
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          size="large"
          sx={{
            margin: 'auto',
            fontSize: '44px',
          }}
        />
        <TextareaAutosize
          placeholder="Отзыв..."
          style={{
            width: '100%',
            marginTop: '10px',
            borderRadius: '6px',
            fontFamily: 'Comfortaa',
            fontSize: '15px',
            padding: '8px',
            height: '250px',
            resize: 'none',
            overflow: 'hidden',
            overflowY: 'scroll',
          }}
          onChange={handleComment}
        />
      </Stack>

      <Stack
        sx={{
          position: 'fixed',
          bottom: 0,
          left: '10%',
          width: '80%',
          paddingBottom: '10px',
        }}
        gap={1}
      >
        <Button
          variant="contained"
          fullWidth
          onClick={submitReview}
          sx={{
            boxShadow: 'none',
          }}
        >
          Отправить
        </Button>
        <Button variant="outlined" fullWidth onClick={goBack}>
          <Typography>Назад</Typography>
        </Button>
      </Stack>

      {errorText && (
        <Alert severity="error">
          <Typography>{errorText}</Typography>
        </Alert>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpenState(false)}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Спасибо за отзыв!
        </Alert>
      </Snackbar>
    </Container>
  );
}
