import { useState } from 'react';
// @mui
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import displayTypesInterface from './displayTypesInterface';

Toggler.propTypes = displayTypesInterface;

export default function Toggler(props) {
  const { handler, values, clear } = props;

  function localHandler(event, value) {
    if (!value) {
      setAlignment(null);
      return clear();
    }
    setAlignment(value);
    return handler([value]);
  }

  const [alignment, setAlignment] = useState(null);

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={localHandler}
      size="medium"
    >
      {values.map((value, index) => {
        return (
          <ToggleButton
            key={index}
            value={value}
            sx={{
              textTransform: 'none',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            {value}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
