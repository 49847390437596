import { useEffect } from 'react';
import {
  Typography,
  Container,
  Card,
  CardContent,
  Alert,
  Box,
  useTheme,
} from '@mui/material';

import { useDispatch } from '../redux/store';
import { clearCart } from '../redux/slices/order';

export default function InvalidTokenPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCart());
  }, [dispatch]);

  const theme = useTheme();
  return (
    <Container>
      <Box
        component="img"
        sx={{
          marginTop: '20px',
          width: '60%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        alt="The house from the offer."
        src="/logo-for-web.png"
      />

      <Card
        sx={{
          marginTop: '20px',
          backgroundColor: theme.palette.menuItemBlock.main,
          boxShadow: 'none',
          borderRadius: '16px',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Alert severity="warning">
            <Typography>Дорогой Гость,</Typography>
            <br />
            <Typography>В нашей системе Вы закреплены за другим столом.</Typography>
            <br />
            <Typography>
              Если Вы пересели за новый стол, попросите официанта пересадить Вас в системе
              и используйте <strong>QR код нового стола</strong> для дальнейших заказов.
            </Typography>
          </Alert>
        </CardContent>
      </Card>
    </Container>
  );
}
