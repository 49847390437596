import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  allCategories: [],
  allTags: [],
};

const slice = createSlice({
  name: 'filters',
  initialState: initialState,
  reducers: {
    initCategories: (state, action) => {
      action.payload.forEach((item) => {
        state.allCategories.push(item);
      });
    },
    initTags: (state, action) => {
      action.payload.forEach((item) => {
        state.allTags.push(item);
      });
    },
  },
});

// Actions
export const { initCategories, initTags } = slice.actions;

// Reducer
export default slice.reducer;
