import PropTypes from 'prop-types';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const theme = createTheme({
    palette: {
      menuItemBlock: {
        main: '#9AD0C2',
      },
      buttonText: {
        main: '#ECF4D6',
      },
      primary: {
        main: '#2D9596',
        contrastText: '#ECF4D6',
      },
    },
    typography: {
      fontFamily: ['Comfortaa'],
      allVariants: {
        color: '#265073'
      }
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
