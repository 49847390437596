import { useEffect, useRef, useState, useCallback } from 'react';
// @mui
import { Stack, AppBar, Slide, Grid } from '@mui/material';

import CategoriesFilter from './components/filters/CategoriesFilter';
import MenuItemNameFilter from './components/filters/MenuItemNameFilter';
import CallWaiterButton from './CallWaiterButton';
import GoToOrdersHistoryButton from './GoToOrdersHistoryButton';

export default function FilterComponent() {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef(null);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [scrollUpPos, setScrollUpPos] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === navbarRef.current) {
          setNavbarHeight(navbarRef.current.offsetHeight);
        }
      }
    });

    if (navbarRef.current) {
      resizeObserver.observe(navbarRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;

    if (prevScrollPos < currentScrollPos && currentScrollPos > navbarHeight) {
      setPrevScrollPos(currentScrollPos);
      setShowNavbar(false);
    }

    if (prevScrollPos < currentScrollPos) {
      setScrollUpPos(currentScrollPos);
      return;
    }

    if (
      prevScrollPos > currentScrollPos &&
      scrollUpPos > currentScrollPos + 150
    ) {
      setShowNavbar(true);
      setPrevScrollPos(currentScrollPos);
      return;
    }
  }, [navbarHeight, prevScrollPos, scrollUpPos]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const fillerDivStyles = {
    height: navbarHeight, // Set the height of the filler div to match the navbar
  };

  const navbarStyles = {
    position: 'fixed',
    left: 0,
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
  };

  return (
    <>
      <div style={fillerDivStyles}></div>
      <Slide appear={false} direction="down" in={showNavbar}>
        <AppBar ref={navbarRef} style={navbarStyles} color="inherit">
          <Stack direction="column" spacing={1} mt={2}>
            
            <Grid
              container
              sx={{
                gridTemplateColumns: '1fr 1fr',
                display: 'grid'
              }}
            >
              <CallWaiterButton />
              <GoToOrdersHistoryButton />
            </Grid>
            <MenuItemNameFilter />
            <CategoriesFilter />
          </Stack>
        </AppBar>
      </Slide>
    </>
  );
}
