import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
// @mui
import {
  Button,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  Modal,
  Stack,
  useTheme,
} from '@mui/material';
import axiosInstance from '../../lib/axiosInstance';

// ----------------------------------------------------------------------

export default function MakeAnOrderButton() {
  const navigate = useNavigate();
  const [isSnackbarOpen, setSnackbarOpenState] = useState(false);
  const [isModalOpen, setModalOpenState] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [primaryPic, setPrimaryPic] = useState('/waiter.png');
  const [secondaryPic, setSecondaryPic] = useState('/robo-waiter.png');
  const handleModalClose = () => {
    setModalOpenState(false);
    setErrorText('');
    navigate('');
  };
  const handleModalOpen = () => {
    setModalOpenState(true);
    navigate('#waitercall');
  };
  const [isLoading, setLoading] = useState(false);

  async function callWaiter() {
    setLoading(true);
    try {
      await axiosInstance.post('/v1/users/public/waiter-call');
    } catch (error) {
      if (_.get(error, 'response.status') === 406) {
        console.log(error);
        setLoading(false);
        setErrorText(
          'Официанта можно вызвать только при наличии оформленного заказа'
        );
        return;
      }
      if (_.get(error, 'response.status') === 417) {
        console.log(error);
        setLoading(false);
        setErrorText(
          'Ваш визит еще не потвержден. Вызов официанта станет доступен как только официант потвердит Ваш визит в системе'
        );
        return;
      }
      if (_.get(error, 'response.status') === 429) {
        console.log(error);
        setLoading(false);
        setErrorText('Вызвать официанта можно только 1 раз в 3 минуты');
        return;
      }
      console.log(error);
      setLoading(false);
      setErrorText('Что-то пошло не так');
      return;
    }
    setSnackbarOpenState(true);
    setLoading(false);
    handleModalClose();
  }

  const isFired = Math.floor(Math.random() * 1000) === 8;
  let pic = primaryPic;
  // this if statement probability is 10% out of 100%
  if (isFired) {
    const pic1 = primaryPic;
    setPrimaryPic(secondaryPic);
    setSecondaryPic(pic1);
    pic = secondaryPic;
  }

  const theme = useTheme();
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => handleModalOpen()}
        sx={{
          textTransform: 'none',
          justifyContent: 'space-evenly'
        }}
      >
        <Typography>Вызов</Typography>
        <Box
          component="img"
          sx={{
            width: '36px',
            display: 'block',
          }}
          src={pic}
        />
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpenState(false)}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Официант в пути
        </Alert>
      </Snackbar>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            borderRadius: '30px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography>Вы точно хотите вызвать официанта?</Typography>
          <Stack direction="row" gap={1} mt={1} justifyContent="space-around">
            <Button variant="outlined" onClick={() => callWaiter()} fullWidth>
              {isLoading && (
                <CircularProgress
                  size={16}
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                />
              )}
              {!isLoading && 'Да'}
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleModalClose()}
              fullWidth
            >
              Нет
            </Button>
          </Stack>
          {errorText && (
            <Alert severity="error" sx={{ marginTop: '8px' }}>
              <Typography>{errorText}</Typography>
            </Alert>
          )}
        </Box>
      </Modal>
    </>
  );
}
